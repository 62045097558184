import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { map } from 'lodash-es'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Spinner, Image } from '@nextui-org/react'
import { useRouter } from 'next/router'
import ImageInfo from '@/components/HomePage/ImageInfo'
import AIImage from './AIImage'

const Component = ({ images, hasMore, onChange, setImages }) => {
  const router = useRouter()
  const { image_id } = router.query

  return (
    <div>
      {images && images.length === 0 ? (
        <div className="flex items-center justify-center pt-16 text-center">
          <Image src="/nodata.png" alt="" className="h-48 w-48 object-contain" disableSkeleton />
        </div>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={onChange}
          hasMore={hasMore}
          threshold={150}
          loader={
            <div className="text-center" key="spin">
              <Spinner />
            </div>
          }
        >
          <ResponsiveMasonry columnsCountBreakPoints={{ 576: 2, 768: 3, 992: 4, 1200: 5 }}>
            <Masonry>
              {map(images, (image, idx) => (
                <AIImage image={image} key={+idx} setImages={setImages} images={images} />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </InfiniteScroll>
      )}
      <ImageInfo image_id={image_id} images={images} />
    </div>
  )
}

export default Component
