import { Divider } from '@nextui-org/react'

export default function Footer() {
  return (
    <div className="pt-24">
      <div className="mx-auto max-w-full px-4">
        <Divider />
        <div className="flex items-center justify-center gap-4 py-10 text-xs text-foreground/80">
          <div className="font-light">@苏州灵越智界科技有限公司</div>
          <a href="https://beian.miit.gov.cn" target="_blank" rel="noreferrer">
            苏ICP备2023029982号
          </a>
          <a href="mailto:fybchristasker@gmail.com">联系我们</a>
        </div>
      </div>
    </div>
  )
}
