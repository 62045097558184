import React, { useState } from 'react'
import { Image, DropdownMenu, Dropdown, DropdownItem, DropdownTrigger, CardFooter, Button, Card, CardHeader } from '@nextui-org/react'
import { useRouter } from 'next/router'
import CustomAvatar from '../CustomAvatar'
import { getToken } from '@/utils/request'
import IconFont from '@/components/IconFonts'
import { addLikeImage, deleteImage, disLikeImage, setImagePrivate, setImagePublic } from '@/services/ai_images'
import { notify } from '@/utils/crypto'
import { useModel } from '@/plugin-model/useModel'

const AIImage = ({ image, setImages }) => {
  const { viewSet } = useModel('view')
  const router = useRouter()
  const [isLiked, setIsLiked] = useState(image?.is_liked_by_user)
  const [likesCount, setLikesCount] = useState(image?.total_likes)

  const handleLike = () => {
    if (getToken()) {
      if (isLiked) {
        disLikeImage(image?.id).then((res) => {
          if (res.code === 200) {
            setIsLiked(false)
            setLikesCount(likesCount - 1)
          } else {
            notify(res.messsage, 'info')
          }
        })
      } else {
        addLikeImage(image.id).then((res) => {
          if (res.code === 200) {
            setIsLiked(true)
            setLikesCount(likesCount + 1)
          } else {
            notify(res.messsage, 'info')
          }
        })
      }
    } else {
      viewSet('loginDialogOpen', true)
    }
  }

  const [isPrivate, setIsPrivate] = useState(image?.is_private)

  const handlePrivate = () => {
    if (getToken()) {
      if (isPrivate) {
        setImagePublic(image?.id, false).then((res) => {
          if (res.code === 200) {
            setIsPrivate(false)
          } else {
            notify(res.messsage, 'info')
          }
        })
      } else {
        setImagePrivate(image?.id, true).then((res) => {
          if (res.code === 200) {
            setIsPrivate(true)
          } else {
            notify(res.messsage, 'info')
          }
        })
      }
    } else {
      viewSet('loginDialogOpen', true)
    }
  }

  const path = router.query.keyword ? router.pathname : router.asPath

  return (
    <div className="my-2 px-2">
      <Card
        className="group h-auto w-full"
        isPressable={image?.cdn_img_url}
        onClick={() => router.push(`${path}?image_id=${image?.id}`, undefined, { shallow: true })}
        disableRipple
      >
        {image?.cdn_img_url && (
          <CardHeader className="absolute top-0 z-20 flex w-full items-center justify-between bg-gradient-to-b from-black to-transparent text-white opacity-100 transition-all group-hover:opacity-100 lg:opacity-0">
            <div className="hidden items-center justify-between gap-2 lg:flex">
              <CustomAvatar
                avatar={image?.author_avatar}
                width={30}
                height={30}
                onClick={(e) => {
                  e.stopPropagation()
                  router.push(`/user/${image?.user_id}`)
                }}
              />
              <div
                className="max-w-[100px] truncate text-sm lg:max-w-[200px]"
                onClick={(e) => {
                  e.stopPropagation()
                  router.push(`/user/${image?.user_id}`)
                }}
                role="presentation"
              >
                {image?.author_nickname}
              </div>
            </div>
            <Button
              variant="light"
              className="ml-auto text-white"
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                handleLike()
              }}
              startContent={<IconFont icon={isLiked ? 'icon-like' : 'icon-dislike'} className={`text-lg ${isLiked ? 'text-rose-500' : ''}`} />}
            >
              {likesCount}
            </Button>
          </CardHeader>
        )}
        <Image src={image?.cdn_img_url || '/nodata.png'} alt="" isBlurred />
        {image?.cdn_img_url && (
          <CardFooter className="absolute bottom-0 z-10 w-full flex-col items-start justify-between gap-3 bg-gradient-to-t from-black to-transparent py-3 text-white opacity-100 transition-all before:hidden before:bg-background/70 group-hover:opacity-100 lg:opacity-0">
            <div className="flex w-full items-center justify-between">
              <div className="line-clamp text-left text-xs">{image?.text_prompts}</div>
              {router.asPath === '/my/profile' && (
                <Dropdown>
                  <DropdownTrigger>
                    <Button radius="full" size="sm" isIconOnly variant="light" className="text-white">
                      <IconFont icon="icon-ellipsis-v" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    variant="flat"
                    items={[
                      {
                        key: 'hide',
                        icon: isPrivate ? 'icon-invisible' : 'icon-visible',
                        label: isPrivate ? '显示图片' : '隐藏图片',
                      },
                      {
                        key: 'delete',
                        icon: 'icon-delete',
                        label: '删除图片',
                      },
                    ]}
                    onAction={(key) => {
                      if (key === 'hide') {
                        handlePrivate()
                      } else {
                        deleteImage(image?.id).then((res) => {
                          if (res.code === 200) {
                            notify('删除成功')
                            setImages((prevImages) => prevImages.filter((item) => item.id !== image.id))
                          } else {
                            notify('删除失败', 'warning')
                          }
                        })
                      }
                    }}
                  >
                    {(item) => (
                      <DropdownItem key={item.key} startContent={<IconFont icon={item.icon} />} color={item.key === 'delete' ? 'danger' : 'default'}>
                        {item.label}
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>
            <Button
              className="hidden lg:flex"
              fullWidth
              color="primary"
              startContent={<IconFont icon="icon-sd" />}
              onClick={() => {
                const baseParams = `?prompt=${image?.generate_type === 'jinshu' ? image?.jinshu?.prompt : image?.text_prompts}&negative_prompts=${image?.negative_prompts ?? ''}`
                navigator.clipboard.writeText(baseParams)
                let url = ''
                if (image?.generate_type === 'jinshu') {
                  url = `/creation/magic_quill${baseParams}`
                } else if (image?.generate_type.includes('stable_diffusion_three')) {
                  url = `/creation/sd_three${baseParams}`
                } else if (image?.generate_type.includes('midjourney')) {
                  url = `/creation/mj${baseParams}`
                } else {
                  url = `/creation${baseParams}`
                }
                router.push(url)
              }}
            >
              生成同款
            </Button>
          </CardFooter>
        )}
      </Card>
    </div>
  )
}

export default AIImage
