import React from 'react'
import { motion } from 'framer-motion'
import { Button, Card, CardBody, CardHeader } from '@nextui-org/react'
import { useRouter } from 'next/router'
import IconFont from '../IconFonts'

const Home = () => {
  const router = useRouter()

  return (
    <div>
      <div className="relative h-full">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
          }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        />
      </div>
      <div className="m-auto w-[1440px] max-w-full p-4">
        <div className="mb-4 mt-12 text-center text-3xl lg:text-4xl">AI智能对话</div>
        <div className="text-fill-transparent m-auto bg-gradient-to-r from-sky-500 to-primary bg-clip-text text-center">
          能交谈、能创作，帮您轻松处理复杂事务，您的生活助手和工作伙伴。
        </div>
        <div className="mt-12 grid grid-cols-1 gap-12 lg:grid-cols-3">
          {[
            ['写作文案', '我博览群书，撰写能力超群，无论是学术论文、小说还是技术文档，我都能游刃有余地完成。', 'text'],
            ['辅助学习', '各类学科我都精通，无论是科学、文学、数学还是艺术，只要是与学习相关的事情，你都可以放心地交给我来处理。', 'study'],
            ['聊天吐槽', '无论有什么烦心事，不管是大事小事，随时都可以找我聊聊吧，我会认真倾听，与你一起分担。', 'chat'],
            ['丰富的语言模型', '这种能力超越了您之前所经历的任何事情，其独特性和难以想象的特质令人惊叹。', 'openai'],
            ['Bot商店', '快速搭建各种 Bot，一键发布至各大社交平台，让你的自动化助手随时随地都能高效运行。', 'bot'],
            ['限时特惠', '免费易用的AI聊天工具，让你轻松体验智能对话的乐趣与便利。', 'discount'],
          ].map((v) => (
            <Card key={v[0]} className="bg-transparent py-4">
              <CardHeader className="flex justify-center">
                <IconFont icon={`icon-${v[2]}`} className="text-5xl" />
              </CardHeader>
              <CardBody>
                <div className="flex flex-col items-center justify-center gap-4">
                  <div className="text-xl font-bold">{v[0]}</div>
                  <div className="my-2 text-xs">{v[1]}</div>
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
        <div className="mt-12 text-center">
          <Button color="primary" size="lg" className="bg-gradient-to-r from-primary to-red-600 px-6 py-1 text-lg" onClick={() => router.push(`/chat`)}>
            免费开始
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Home
