import { Button } from '@nextui-org/react'
import { useState } from 'react'
import { useTheme } from 'next-themes'
import IconFont from './IconFonts'

export default function ThemeSwitcher() {
  const { theme, setTheme } = useTheme()
  const [mode, setMode] = useState(theme === 'light' ? 'icon-light' : 'icon-dark')

  const handleClick = () => {
    if (theme === 'light') {
      setTheme('dark')
      setMode('icon-dark')
    } else {
      setTheme('light')
      setMode('icon-light')
    }
  }

  return (
    <Button isIconOnly onClick={handleClick} disableRipple className="hidden">
      <IconFont icon={mode} className="text-lg" />
    </Button>
  )
}
