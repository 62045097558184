import React from 'react'
import { Accordion, AccordionItem } from '@nextui-org/react'

const Page = () => {
  return (
    <Accordion>
      <AccordionItem key="1" title="1.我生成的图片会被他人获取牟利，用到别的地方吗？">
        用户上传的原图相当于绘画的底稿，不会被AI进行学习训练；对AI模型的训练是无法在绘画过程中进行的，并且原图属于用户，Ground AI
        不会挪用用户的数据进行相关开发工作。如果你有隐私数据泄漏、上传的原图被作为他用这方面的顾虑，那你完全不用担心。Ground AI
        对用户隐私安全方面高度重视，我们将对你上传的图片进行特殊加密处理。
      </AccordionItem>
      <AccordionItem key="2" title="2.图片版权归属">
        Ground AI的模型都是基于开源模型进行优化、深度定制而成的。我们将生成图片的版权完全授予生成者，所以，你生成的图片，如无意外情况，版权是归属于你的。
        值得注意的是，由于这些开源模型本身采用大量图片素材作为机器学习的样本，当你指定某个画家的风格来生成图片时，其风格可能和这名画家较为相似，并可能因此引起潜在的争议。所以，我们建议你在指定画家时使用已经去世的画家作为风格修饰，以避免此类争议的产生。
      </AccordionItem>
      <AccordionItem key="3" title="3.如何获得更多积分">
        你可以通过以下行为获取用于创作的积分：注册成功并首次登录后，将免费获得积分作为初始体验积分；可通过邀请身边的朋友一起体验Ground
        AI来获得更多积分，通过你的链接进入并登录后，你将获得积分；后续我们将陆续发布新的任务和更多活动等来帮助你获得更多积分，请持续关注。
      </AccordionItem>
      <AccordionItem key="4" title="4.如何邀请好友和查看自己邀请的好友？">
        可以在邀请好友中生成自己的专属邀请链接进行邀请，也可以把作品分享出去进行邀请。查看自己已邀请的好友可前往邀请好友列表。
      </AccordionItem>
      <AccordionItem key="5" title="5.如何查看自己账号的详细信息？">
        点击右上角的用户头像，进入个人中心，可以查看自己的详细信息。
      </AccordionItem>
      <AccordionItem key="6" title="6.为什么一样的关键词，我画出的图没有别人的好看">
        Ground
        AI绘画的机制决定出图效果是随机性的，即使是完全一样的关键词，也很难复刻出一模一样的作品。那些优秀的作品可能是配置了风格、艺术家，或多次绘制后得出的结果。所以，大家可以多进行尝试，或对关键词进行修改、加入自己的创意，相信你也可以画出属于自己的优秀的作品。
      </AccordionItem>
    </Accordion>
  )
}

export default Page
